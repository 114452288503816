import React, {Component} from 'react'
import {Button, Modal, Tab, Checkbox, Input} from 'semantic-ui-react'
import Utils from './../../modules/utils'
import $ from 'jquery'
import Constants from './../../modules/constants'
import './shipped-cancel-modal.css'
import anyQbEntered from './any-qb-entered.js'

class ShippedCancelModal extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {activeIndex: null}
    this.submitForm = this.submitForm.bind(this)
  }

  submitForm(e) {
    e.preventDefault()

    let {onClose, id} = this.props
    let {activeIndex} = this.state

    if (activeIndex == null) {
      activeIndex = this.props.activeIndex
    }

    let poIds = (id == null ? Object.keys(window.poIds) : [id])

    let data = {purchase_order_ids: poIds}

    if (activeIndex == 0) {
      if (anyQbEntered(poIds, this.props.actualPOs)) {
        alert((poIds.length > 1 ? 'Orders' : 'Order') + ' must be marked QB-Entered before being marked as shipped!')
        return
      }

      Utils.post('sales_order_shipped', data, (err, res) => {
        if (err) {
          alert('There was an error when trying to mark orders as Shipped.')
        } else {
          if (res.data.success) {
            alert((poIds.length > 1 ? 'Orders are' : 'Order is') + ' now shipped')
            this.props.updateOrders(poIds, 'Shipped', false)
          }
        }
      })
    } else if (activeIndex == 2) {
      data.newPonumber = $('#combined-ponumber').val()

      Utils.post('create_combined_order', data, (err, res) =>
      {
        if (err) {
          alert('There was an error when trying to combine orders.')
        } else {
          if (res.data.success) {
            alert('Orders are now combined')
            window.location.reload()
          }
        }
      })
    } else {
      let flaggedForUpdate = $('#flag-for-update').is(':checked')
      data.addBackUnits = $('#add-back-units').is(':checked')
      data.flaggedForUpdate = flaggedForUpdate

      Utils.post('sales_order_cancel', data, (err, res) =>
      {
        if (err) {
          alert('There was an error when trying to cancel orders.')
        } else {
          if (res.data.success) {
            alert((poIds.length > 1 ? 'Orders are' : 'Order is') + ' now cancelled')
            this.props.updateOrders(poIds, 'Cancelled', flaggedForUpdate)
          }
        }
      })
    }

    onClose()
  }

  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex })

  render() {
    let {open, onClose, id, activeIndex} = this.props

    const panes = [
      {
        menuItem: {content: 'Ship', icon: 'ship'},
        render: () => <Tab.Pane>
          <center style={{height: '20px'}}></center>
          <Button color='orange' fluid onClick={this.submitForm}>Confirm Shipped</Button>
        </Tab.Pane>
      },
      {
        menuItem: {content: 'Cancel', icon: 'trash'},
        render: () => <Tab.Pane>
          <center>
            <Checkbox id="add-back-units" toggle label='Add back units'
              style={{marginBottom: '40px', marginTop: '25px'}} />
          </center>
          <center>
            <Checkbox id="flag-for-update" toggle label='Flag for update'
              style={{marginBottom: '40px', pointerEvents: 'none', opacity: 0.3}} defaultChecked/>
          </center>
          <Button negative fluid onClick={this.submitForm}>Confirm Cancelled</Button>
        </Tab.Pane>
      },
      // {
      //   menuItem: {content: 'Combine', icon: 'clone'},
      //   render: () => <Tab.Pane>
      //     <center>
      //       <Input id="combined-ponumber" fluid placeholder='PO #' style={{marginBottom: '40px', marginTop: '25px'}}/>
      //     </center>
      //     <Button color='yellow' fluid onClick={this.submitForm}>Create Combined Order</Button>
      //   </Tab.Pane>
      // },
    ]

    return (
      <Modal size={'mini'} open={open} onClose={onClose} closeIcon>
        <Tab panes={panes} defaultActiveIndex={activeIndex}
          className="shipped-cancel-tabs" onTabChange={this.handleTabChange} />
      </Modal>
    )
  }
}

export default ShippedCancelModal
