const allInHouseBrands = [
  'BIG DREAMS',
  'CHOOSE HAPPY',
  'GIRLS',
  'GIRL SQUAD',
  'THIS GIRL CAN',
  'TODDLER',
  'TOTALLY ADORBS',
  'TWEEN QUEEN',
  'TOTALLY TWEEN'
]

const quickFixAllInHouseBrands = (brand) => {
   if (brand.includes('ALL INHOUSE BRANDS')) {
     brand = allInHouseBrands
   }

   return brand
}

export {quickFixAllInHouseBrands, allInHouseBrands}
