import React, { Component } from 'react'
import $ from 'jquery'
import './CadCard.css'
import {getApiEndpoint} from './getApiEndpoint.js';
import {Card, Accordion, Popup, Label} from 'semantic-ui-react'
import {connect} from 'react-redux'
import * as cadActions from './actions/cadActions'
import {bindActionCreators} from 'redux'
import {Auth0Context} from './contexts/auth-context'
import getOrderPanels from './components/cads/get-order-panels.js'
import {isWarehouse} from './components/cads/is-role.js'
import {requestBrandApproval, approveCadLicense, canApproveBrandsList} from './components/cads/brand-approval/index'
import getMeta from './components/cad-card/get-meta'
import getHeader from './components/cad-card/get-header'
import {getExtra} from './components/cad-card/get-extra'
import {checkCanSeePrice} from './components/cads/can-see-price'
import {printImageUrl} from './components/cads/prints/show-button'

class CadCard extends Component {
  static contextType = Auth0Context

  constructor(props) {
    super(props)
    this.state = {
      reportQcIssueModalOpen: false,
      qcIssue: [],
      cad: this.props.cad,
      showSecondImage: false,
      showPrintImage: false
    }

    this.cadCardEllipsis = this.cadCardEllipsis.bind(this)
    this.handleCadCheckboxChange = this.handleCadCheckboxChange.bind(this)
    this.showLinked = this.showLinked.bind(this)
    this.updateQcIssueData = this.updateQcIssueData.bind(this)
    this.qcOnChange = this.qcOnChange.bind(this)
    this.reportQcIssueModalClose = this.reportQcIssueModalClose.bind(this)
    this.saveQcIssueModal = this.saveQcIssueModal.bind(this)
    this.toggleSecondImage = this.toggleSecondImage.bind(this)
    this.togglePrintImage = this.togglePrintImage.bind(this)
  }

  toggleSecondImage() {
    this.setState({showSecondImage: !this.state.showSecondImage, showPrintImage: false})
  }

  scrollToTop() {
    window.scrollTo(0,0);$('#scrollToTop').hide();
  }

  reportQcIssueModalClose() {
    this.setState({reportQcIssueModalOpen: false})
  }

  qcOnChange(event, {value}) {
    this.setState({qcIssue: value});
  }

  saveQcIssueModal() {
    if (this.state.qcIssue.length == 0) {
      alert('Please select at least one QC!');
    } else {
      let qcIssue = this.state.qcIssue;
      let cadId = window.reportQcIssueCadId;

      let profile = JSON.parse(sessionStorage.getItem('user'));
      let user_name;
      if (profile.name != null) {
        user_name = profile.name;
      } else {
        user_name = profile.email;
      }
      let user_id = profile.user_id;

      $.ajax({
        type: "POST",
        url: getApiEndpoint() + 'qc_issue',
        data: {
          cad_id: cadId,
          qcIssue: qcIssue,
          user_name: user_name,
          user_id: profile.user_id,
          user_email: profile.email
        },
        success: function (data) {
            //on success
            this.props.onShowReportQcIssueSuccess()
            this.reportQcIssueModalClose();
            this.scrollToTop();

            if (this.state.cad.qc_issue_total == null) {
              this.setState({cad: {...this.state.cad, qc_issue_total: 0} })
            } else {
              this.setState({cad: {...this.state.cad, qc_issue_total: this.state.cad.qc_issue_total+1 } })
            }

            window.cadDescription = {}

        }.bind(this),
        error: function (error) {
            // handle error
            alert('There was an error when trying to report QC.')
        },
      });
    }
  }

  cadCardEllipsis(cad, e, {value}) {
    let {id} = cad

    if (value == "View") {
      window.open('/#/view-cad/' + id)
    } else if  (value == "Edit") {
      window.open('/#/edit-cad/' + id)
    } else if (value == "Request Brand Approval") {
      requestBrandApproval([id], this.props.clearCadCard)
    } else if (value == "Approve Brand") {
      approveCadLicense([id], true, this.props.clearCadCard)
    } else if (value == "Reject Brand") {
      approveCadLicense([id], false, this.props.clearCadCard)
    } else if  (value == "Add to Line Sheet") {
      this.props.addToLineSheet()
      window.addToPlaylistCadId = id
      window.addToPlaylistCadStyleNumber = cad.style_number
    } else if (value == "Show Similar") {
      this.setState({showSimilarStyleNumber: cad.style_number})
      this.setSimilarFilters(cad)
      window.scrollTo(0, 0)
    }
  }

  handleCadCheckboxChange(cadId) {
    var checked = $('#cad-checkbox-'+cadId).is(':checked')
    if (checked) {
      window.cadIds[cadId] = true
    } else {
      delete window.cadIds[cadId]
    }
    this.props.updateNumSelected()
  }

  showLinked(family_id) {
    let value = 'linked:'+family_id
  }

  updateQcIssueData(cad_id) {
    $.get(process.env.REACT_APP_API_URL + '/api/qc_issue_data?cad_id=' + cad_id, function (result) {
      setTimeout(function () {
        $('#qc-issues-'+cad_id).html(result.data);
      }.bind(this), 100);
    }.bind(this));
  }

  togglePrintImage() {
    this.setState({showPrintImage: !this.state.showPrintImage, showSecondImage: false})
  }

  render() {
    const {user, customer} = this.context
    let orgId = customer.id
    let {cad, correctPin, doingPONumberSearch, index} = this.props

    let role = sessionStorage.getItem('currentUserRole')
    let isAdmin = ['admin', 'superadmin', 'finance'].includes(role)
    let isSales = ['sales', 'sales_assistant', 'inventory'].includes(role)
    let canApproveBrands = !!user && canApproveBrandsList.includes(user.email)

    let imageUrl, imageUrl2, cardClass = ''
    let s3BucketName = cad.old_s3_bucket ? process.env.REACT_APP_OLD_S3_BUCKET_NAME : process.env.REACT_APP_S3_BUCKET_NAME
    let s3BucketName2 = cad.old_s3_bucket2 ? process.env.REACT_APP_OLD_S3_BUCKET_NAME : process.env.REACT_APP_S3_BUCKET_NAME
    if (cad.filename == null) {
      if (orgId == 5) {
        imageUrl = 'https://s3.amazonaws.com/erp-rails-usa-production/logos/la_fashion_logo.png?index=' + index
        cardClass = 'missing-image'
      } else {
        imageUrl = 'https://s3.amazonaws.com/ihlgroup-app-production/logo_new.png?index=' + index
        cardClass = 'missing-image'
      }
    } else if (cad.have_thumb) {
      imageUrl = 'https://s3.amazonaws.com/' + s3BucketName + '/cad_thumb/' + cad.filename.split('/')[0] + '/thumb.jpg'
    } else {
      imageUrl = 'https://s3.amazonaws.com/' + s3BucketName + '/cad/' + cad.filename
    }

    if (cad.filename2 == null) {
      //missing second image
    } else if (cad.have_thumb2) {
      imageUrl2 = 'https://s3.amazonaws.com/' + s3BucketName2 + '/cad_thumb/' + cad.filename2.split('/')[0] + '/thumb.jpg'
    } else {
      imageUrl2 = 'https://s3.amazonaws.com/' + s3BucketName2 + '/cad/' + cad.filename2
    }

    let description
    if (window.cadDescription[cad.id] == null) {
      let panels = []
      if (!isWarehouse() || doingPONumberSearch()) {
        panels = getOrderPanels(this.props.state, this.props, cad.style_number, cad.id, checkCanSeePrice(user, this.props.cadReducer.defaultAccount))
      }

      description = (
        <div>
          {
            (cad.name == '' || cad.name == null)
            ?
            ''
            :
            cad.name
          }
          {
            panels.length == 0
            ?
            null
            :
            <Accordion panels={panels} exclusive={false}/>
          }
        </div>
      )

      window.cadDescription[cad.id] = description
    } else {
      description = window.cadDescription[cad.id]
    }

    let image = (
      <div className="image">
        {
          cad.fabrication == '' || cad.fabrication == null
          ?
          null
          :
          <Label color='blue' size='tiny' ribbon style={{position: 'absolute', left: '-14px'}}>{cad.fabrication}</Label>
        }

        <Popup content={'Click for fullscreen mode!'} position='top right'
          trigger={
            <img src={this.state.showPrintImage ? printImageUrl(cad.id, index) : (this.state.showSecondImage ? imageUrl2 : imageUrl)}/>
          }
        />
      </div>
    )

    return (
      <Card
        className={cardClass}
        id={'cad-card-'+cad.id}
        image={image}
        header={getHeader(isAdmin, canApproveBrands, cad, this.cadCardEllipsis, this.handleCadCheckboxChange, this.showLinked, this.state.reportQcIssueModalOpen, this.reportQcIssueModalClose, this.qcOnChange, this.saveQcIssueModal, this.props.clearCadCard)}
        meta={getMeta(this.props.cadReducer, cad)}
        description={description}
        extra={getExtra(this.props.cadReducer, cad, imageUrl, imageUrl2, this.togglePrintImage, this.state.showPrintImage, this.toggleSecondImage, this.state.showSecondImage, correctPin, this.props.salesHistoryAccount, this.props.removeFromCart, this.props.addToCart, orgId)}
        style={cad.is_deleted ? {opacity: 0.3, pointerEvents: 'none'} : {}}
      />
    )
  }
}

const mapStateToProps = state => ({
 ...state
})

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(cadActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CadCard)
