const setBrandApprovalData = (contactId, accountIds, companies, isEditPage, setBrandApprovalData, setRemoveStyles, brands, categories) => {
  if (accountIds == null || companies == null) {
    setTimeout(() => {setBrandApprovalData(contactId)}, 500)

  } else {
    let accountId = accountIds[contactId]
    let account = companies.find(x => x.id == accountId)
    let brandApprovalData = account == null ? [] : account.companyBrands

    //only need approved: true and startDate todays date or after
    //TODO: make todayDate in EST, currently will be UTC, so could create an error
    //probably get from API or install moment.js
    let todayDate = new Date().toISOString().split('T')[0]

    brandApprovalData = brandApprovalData.filter((brandApproval) => {
      return (brandApproval.approved && (todayDate >= brandApproval.startDate || brandApproval.startDate == null))
    })

    let brandApproval = {}

    brandApprovalData.forEach((row) => {
      let brandName = brands.filter((x) => {return x.id == row.brandId})[0].name
      let categoryNames = row.category.map((categoryId) => {
        return categories.filter((x) => {return x.id == categoryId})[0].name
      })
      brandApproval[brandName] = categoryNames
    })

    setTimeout(() => {
      if (!isEditPage) {
        setRemoveStyles()
      }
    },0)

    return {brandApproval}
  }
}

export {setBrandApprovalData}
