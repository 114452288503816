import React from 'react'
import {Button} from 'semantic-ui-react'
import {validStyleNumber} from './valid-style-number'
import {runStyleCodes} from './run-style-codes'
import {externalLink} from '../upload-cad/external-link'

const inputStyleNumberButton = (updateStyleNumber, disabled) => {
  return (
    <Button color='green' disabled={disabled} style={{float: 'right'}} onClick={() => inputStyleNumber(updateStyleNumber)}>Input Style Number</Button>
  )
}

const inputStyleNumber = (updateStyleNumber) => {
  let styleNumber = window.prompt('Input style number:')

  if (styleNumber == null) return

  if (validStyleNumber(styleNumber)) {
    runStyleCodes(styleNumber, updateStyleNumber)
  } else {
    alert('INVALID style number!')
  }
}

export {inputStyleNumberButton}
