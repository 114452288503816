const logoUrl = (orgId) => {
  switch (orgId) {
    case 2:
      return 'https://erp-rails-usa-production.s3.us-east-1.amazonaws.com/logos/mega_logo.png'
    case 5:
      return 'https://erp-rails-usa-production.s3.us-east-1.amazonaws.com/logos/la_fashion_logo.png'
    default:
      return process.env.REACT_APP_LOGO_IMG_SRC
  }
}

export {logoUrl}
