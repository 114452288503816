import React, {Component} from 'react'
import {Button, Modal, Input, Dropdown} from 'semantic-ui-react'
import {saveContact} from './save'
import {dateLabel} from '../new-vpo-page/cad-details-section/date-label'
import {accountOptions} from './account-options'

class NewContactModal extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      open: false,
      accountName: '',
      buyerName: '',
      existingAccount: null
    }

  }

  openModal = () => {
    this.setState({
      open: true,
      accountName: '',
      buyerName: '',
      existingAccount: null
    })
  }

  closeModal = () => {
    this.setState({open: false})
  }

  saveContact = () => {
    let {userId, addToNewContacts} = this.props
    let {accountName, buyerName, existingAccount} = this.state

    if (buyerName.length < 3 || (accountName.length < 3 && existingAccount == null)) {
      alert('All fields required')
      return
    }

    saveContact(accountName, buyerName, existingAccount, userId, (err, res) => {
      if (err) {
        alert('There was an error when trying to save new contact.')
      } else {
        let {accountName, contactId} = res.data
        this.closeModal()
        addToNewContacts({'key': contactId, 'text': accountName + ' (' + buyerName + ')', 'value': contactId})
      }
    })
  }

  fieldOnChange = (event, {value, name}) => {
    let newState = {}
    if (name == 'accountName') {
      newState['existingAccount'] = null
    }
    if (name == 'existingAccount') {
      newState['accountName'] = ''
    }
    newState[name] = value
    this.setState(newState)
  }

  render() {
    let {customer} = this.props
    let {open, accountName, buyerName, existingAccount} = this.state

    return (
      <React.Fragment>
        <Modal
          open={open}
        >
          <Modal.Header>New contact</Modal.Header>
          <Modal.Content style={{fontSize: '20px'}}>
            <div style={{width: '45%', display: 'inline-block'}}>
              {dateLabel('Account name', {marginBottom: '5px'}, accountName == '')}
              <Input placeholder="Account name" name='accountName' fluid
                value={accountName}
                onChange={this.fieldOnChange}
              />
            </div>
            <div style={{fontWeight: 'bold', textAlign: 'center', display: 'inline-block', width: '10%'}}>
              OR
            </div>
            <div style={{width: '45%', display: 'inline-block'}}>
              {dateLabel('Add to existing account', {marginBottom: '5px'}, existingAccount == null)}
              <Dropdown placeholder='Add to existing account' fluid selection search
                options={customer == null ? [] : accountOptions(customer)}
                onChange={this.fieldOnChange} name='existingAccount' value={existingAccount}
              />
            </div>
            {dateLabel('Buyer name', {marginBottom: '5px', marginTop: '20px'}, buyerName == '')}
            <Input placeholder="Buyer name" name='buyerName' fluid
              value={buyerName} style={{width: '45%'}}
              onChange={this.fieldOnChange}
            />

          </Modal.Content>
          <Modal.Actions>
            <Button color='black' onClick={this.closeModal}>
              Cancel
            </Button>
            <Button
              content="Save contact"
              labelPosition='right'
              icon='checkmark'
              onClick={this.saveContact}
              positive
            />
          </Modal.Actions>
        </Modal>



        <Button size='mini' primary onClick={this.openModal}>Generate new contact</Button>
      </React.Fragment>
    )
  }
}

export default NewContactModal
