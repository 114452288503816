const defaultContactName = 'IHL Inventory'
const nonInventoryId = 670

const contactOptions = (customer, vpoPage = false) => {
  let contacts = customer.distinctAccounts.filter(x => !x.notUsing && x.status != 'inactive')

  let {companies} = customer

  let result = contacts.map(x => ({key: x.id, text: companies.find(i => i.id == x.companyId).name + ' (' + x.mainContactPersonName + ')', value: x.id})).sort((a, b) => {
    if (a.text.toUpperCase() > b.text.toUpperCase()) return 1
    if (b.text.toUpperCase() > a.text.toUpperCase()) return -1
    return 0
  })

  if (vpoPage) {
    result.unshift({key: nonInventoryId, text: 'Non-Inventory', value: nonInventoryId})
    result.unshift({key: defaultContactName, text: defaultContactName, value: defaultContactName})
  }

  return result
}

export {contactOptions, defaultContactName}
