import React, {Component} from 'react'
import SectionTitle from './components/new-vpo-page/section-title.js'
import SelectBuyer from './components/new-vpo-page/select-buyer.js'
import CadDetailsSection from './components/new-vpo-page/cad-details-section.js'
import CompleteVendorOrder from './components/new-vpo-page/complete-vendor-order.js'
import {sortMapNameOptions} from './components/purchase-orders/sort-map-id-name-options.js'
import {Auth0Context} from './contexts/auth-context'
import {successMessage, splitUpMessage} from './components/new-vpo-page/success-message.js'
import {getVendorOrder} from './components/new-vpo-page/get-vendor-order.js'
import {Dimmer, Loader} from 'semantic-ui-react'
import OrderSummary from './components/new-vpo-page/order-summary.js'
import LogOfChanges from './components/new-vpo-page/log-of-changes.js'
import VpoStages from './components/vpo-stages/index.js'
import {hr} from './components/new-vpo-page/hr'

class NewVpoPage extends Component {

  static contextType = Auth0Context

  constructor(props)
  {
    super(props)
    this.state = {
      activeSection: 0,
      isComplete: [false, false, false],
      isSuccess: false,
      successId: null,
      action: null,
      isSplitUp: false,
      splitUpId: null,
      isEditPage: this.props.id != null,
      loading: this.props.id != null
    }

    this.markAsComplete = this.markAsComplete.bind(this)
    this.goToNextSection = this.goToNextSection.bind(this)
    this.editSection = this.editSection.bind(this)
    this.setOptions = this.setOptions.bind(this)
    this.stopLoading = this.stopLoading.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    window.newVpoPage = {fabricationOptions: []}
    this.setOptions()

    if (window.location.href.indexOf('?success=') > -1) {
      let successId = window.location.href.split('?success=')[1].split('&action')[0]
      let newState = {isSuccess: true, successId}

      let action = window.location.href.split('&action=')[1]
      if (action != null && window.skipAction != true) {
        newState['action'] = action
      }

      this.setState(newState)
    }

    if (window.location.href.indexOf('?split_up=') > -1) {
      let splitUpId = window.location.href.split('?split_up=')[1]
      this.setState({isSplitUp: true, splitUpId})
    }

    if (this.props.id != null) {
      getVendorOrder(this.props.id)
    }
  }

  setOptions() {
    let {customer} = this.context
    if (customer == null) {
      setTimeout(this.setOptions, 500)
    } else {
      let fabricationOptions = sortMapNameOptions(customer.fabrications)
      window.newVpoPage.fabricationOptions = fabricationOptions
    }
  }

  markAsComplete(sectionIndex) {
    let {isComplete} = this.state
    isComplete[sectionIndex] = true
    this.setState(isComplete)
    this.goToNextSection()
  }

  goToNextSection() {
    let {activeSection} = this.state
    activeSection += 1
    this.setState({activeSection})
  }

  editSection(num) {
    this.setState({activeSection: num})
  }

  stopLoading() {
    this.setState({loading: false})
  }

  render() {
    let {customer} = this.context
    let {activeSection, isSuccess, successId, action, isSplitUp, splitUpId, isEditPage, loading} = this.state

    return (
      <div style={{background: 'rgb(250,250,250)', minHeight: '100vh'}}>
        {
          loading
          ?
          <Dimmer active>
            <Loader size='massive'>Loading</Loader>
          </Dimmer>
          :
          null
        }
        <div style={{width: '950px', margin: 'auto', paddingBottom: '200px'}}>
          {isSuccess ? successMessage(successId, action) : null}
          {isSplitUp ? splitUpMessage(splitUpId) : null}
          <h2 style={{fontWeight: 'lighter', padding: '40px 20px', paddingBottom: '25px'}}>
            {
              isEditPage
              ?
              'Edit vendor order #' + this.props.id
              :
              'New vendor order'
            }
          </h2>

          {
            isEditPage && !loading
            ?
            <React.Fragment>
              <OrderSummary id={this.props.id} />
              <VpoStages id={this.props.id}/>
              <LogOfChanges />
            </React.Fragment>
            :
            null
          }

          <div style={{border: '1px solid rgb(212,212,212)', padding: '30px 20px', background: 'white'}}>
            <SelectBuyer
              markAsComplete={this.markAsComplete.bind(null, 0)}
              isActive={activeSection == 0}
              editSection={this.editSection.bind(null, 0)}
              isEditPage={isEditPage}
              stopLoading={this.stopLoading}
              titleNum='1'
              customer={customer}
            />
            {hr}
            <CadDetailsSection
              markAsComplete={this.markAsComplete.bind(null, 1)}
              isActive={activeSection == 1}
              editSection={this.editSection.bind(null, 1)}
              isEditPage={isEditPage}
              titleNum='2'
            />
            {hr}
            <CompleteVendorOrder
              history={this.props.history}
              isActive={activeSection == 2}
              editSection={this.editSection.bind(null, 2)}
              isEditPage={isEditPage}
              id={this.props.id}
              titleNum='3'
            />
          </div>
        </div>
      </div>
    )
  }
}

export default NewVpoPage
