import React, { Component } from 'react'
import $ from 'jquery'
import { Icon, Button, Message, Image } from 'semantic-ui-react'
import {Auth0Context}    from './contexts/auth-context'
import Utils from "./modules/utils"

import {missingItemStyle} from './components/home/missing-item-style'
import TodaysRefNumbers from './components/home/todays-ref-numbers'
import {createdAtToAmPm} from './components/view-cad/created-at-to-am-pm'
import DownloadVpos from './components/home/download-vpos'
import YourStats from './components/home/your-stats'
import StockReportDebug from './components/stock-report-debug'

const divStyle = {minHeight: '100vh', background: 'url(' + process.env.REACT_APP_HOME_IMG_SRC + ')'}
const divStyle2 = Object.assign({}, divStyle, {padding: '100px'})

class HomePage extends Component {
  static contextType = Auth0Context

  constructor(props) {
    super(props)
    this.state = {
      user_email: null,
      user_role: null,
      loadingSalesHistoryByStyleNumber: false,
      mtd: null,
      ytd: null,
      loadingMissingItems: false,
      loadingRefNumbers: false,
      missingItems: [],
      refNumbers: [],
      times: [],
      ponumbers: [],
      reps: [],
      filenames: []
    }

    this.getUserData = this.getUserData.bind(this)
    this.getSalesData = this.getSalesData.bind(this)
    this.calculateStats = this.calculateStats.bind(this)
    this.getMissingItems = this.getMissingItems.bind(this)
    this.getRefNumbers = this.getRefNumbers.bind(this)
  }

  componentDidMount() {
    this.getUserData()
    window.scrollTo(0, 0)
  }

  getUserData() {
    let {user} = this.context

    if (user == null) {
      setTimeout(this.getUserData, 300)
    } else {
      this.setState({
        user_id: user.id,
        user_email: user.email,
        user_name: user.name,
        user_role: user.role
      })
      if (user.role == "sales") {
        this.getSalesData()
      }
      if (['sales', 'sales_assistant'].includes(user.role)) {
        this.getRefNumbers()
      }
      if (user.role == "admin") {
        this.getMissingItems()
      }
    }
  }

  getRefNumbers() {
    this.setState({loadingRefNumbers: true})

    Utils.get('todays_ref_numbers/'+this.state.user_id, (err, res) =>
    {
      this.setState({loadingRefNumbers: false})
      if (err) {
        this.setState({loadingRefNumbers: true})
        setTimeout(this.getRefNumbers, 300)
      } else {
        let times = res.data.times.map(createdAtToAmPm)
        this.setState({refNumbers: res.data.refNumbers, times, ponumbers: res.data.ponumbers, reps: res.data.reps, filenames: res.data.filenames})
      }
    })
  }

  getMissingItems() {
    this.setState({loadingMissingItems: true})

    Utils.get('qb_import_missing_items', (err, res) =>
    {
      this.setState({loadingMissingItems: false})
      if (err) {
        alert('There was an error when trying to get missing items.')
      } else {
        this.setState({missingItems: res.data})
      }
    })
  }

  getSalesData() {
    this.setState({loadingSalesHistoryByStyleNumber: true})

    //?is_guest=' + isGuest + '&defaultAccount=' + this.props.cadReducer.defaultAccount

    Utils.get('sales_history_by_style_number_data', (err, res) =>
    {

      if (res != null) {
        window.salesAccountDataByStyleNumber = JSON.parse(res.data.salesAccountDataDup)
        window.salesOrderDataByStyleNumber = JSON.parse(res.data.salesOrderDataDup)
        this.calculateStats()
      }

      this.setState({
        loadingSalesHistoryByStyleNumber: false
      })
    })
  }

  calculateStats() {
    let {user_email} = this.state

    let myOrders = []

    let date = new Date()
    let firstDayMonth = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0]
    let firstDayYear = new Date(date.getFullYear(), 0, 1).toISOString().split('T')[0]

    var salesAccountKeys = Object.keys(window.salesAccountDataByStyleNumber)

    for (let i=0; i<salesAccountKeys.length; i++) {
      let style = salesAccountKeys[i]

      let invoices = window.salesAccountDataByStyleNumber[style]

      for (let j=0; j < invoices.length; j++) {
        let invoice = invoices[j]

        if (invoice.rep == user_email.replace('example.com', 'ihlgroup.com')) {
          myOrders.push(invoice)
        }
      }
    }

    var salesOrderKeys = Object.keys(window.salesOrderDataByStyleNumber);

    for (var i=0; i<salesOrderKeys.length; i++) {
      var salesOrderKey = salesOrderKeys[i];

      var invoices = window.salesOrderDataByStyleNumber[salesOrderKey];

      for (var j=0; j< invoices.length; j++) {
        var invoice = invoices[j];

        if (invoice.rep == user_email.replace('example.com', 'ihlgroup.com')) {
          myOrders.push(invoice)
        }

      }
    }

    window.myOrders = myOrders

    let mtd = 0
    let ytd = 0



    for (let i=0; i < myOrders.length; i++ ) {
      let order = myOrders[i]

      if (order.date_was_entered != null && order.date_was_entered >= firstDayMonth) {
        mtd += Number(order.sales_price) * Number(order.quantity)
      }

      if (order.date_was_entered != null && order.date_was_entered >= firstDayYear) {
        ytd += Number(order.sales_price) * Number(order.quantity)
      }

    }

    mtd = mtd.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    ytd = ytd.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")


    //then add the gross amounts
    this.setState({mtd, ytd})


  }

  downloadQbImportExcel() {
    window.location = process.env.REACT_APP_API_URL + '/api/sales_order_qb_import'
  }

  downloadAllQbImportExcel() {
    window.location = process.env.REACT_APP_API_URL + '/api/all_sales_order_qb_import'
  }

  downloadYesterdayQbImportExcel() {
    window.location = process.env.REACT_APP_API_URL + '/api/yesterday_sales_order_qb_import'
  }

  downloadYesterday2QbImportExcel() {
    window.location = process.env.REACT_APP_API_URL + '/api/day_before_yesterday_sales_order_qb_import'
  }

  downloadQbImportExcelRefnumber() {
    let refnumber = $('#refnumber').val()
    if (refnumber != '') {
      window.location = process.env.REACT_APP_API_URL + '/api/sales_order_qb_import/' + refnumber
    }
  }

  render() {
    const {customer} = this.context
    let orgId = customer == null ?  null : customer.id
    let {user_email, user_name, user_role, loadingSalesHistoryByStyleNumber, loadingMissingItems, loadingRefNumbers, mtd, ytd, missingItems, refNumbers, times, ponumbers, reps, filenames} = this.state

    const today = new Date()
    const yesterday = new Date(today)
    const yesterday2 = new Date(today)

    yesterday.setDate(yesterday.getDate() - 1)
    yesterday2.setDate(yesterday2.getDate() - 2)

    if (orgId > 2) {
      return (
        <div style={divStyle2}>
          <h1 style={{fontWeight: 'lighter', marginBottom: '25px', marginTop: '10vh'}} className="cursive">
            Hey {user_name},<br/><br/>
            {process.env.REACT_APP_HOME_TEXT} to {customer.name}
          </h1>
        </div>
      )
    }

    if (orgId == null) {
      return <div style={divStyle}/>
    }

    return (
      <div style={divStyle}>

        {
          false && user_email != null && user_role == 'sales'
          ?
          <div style={{paddingTop: '60px'}}>
            <YourStats/>
          </div>
          :
          null
        }

        {
          user_email == null
          ?
          null
          :
          <div style={{width: '80vw', margin: 'auto', fontSize: '150%', lineHeight: '2rem', paddingTop: '100px'}}>
            {
              false && user_role == 'sales'
              ?
              null
              :
              <h1 style={{fontWeight: 'lighter', marginBottom: '25px'}} className="cursive">
                {process.env.REACT_APP_HOME_TEXT}, {user_name}
              </h1>
            }

            {
              ['admin', 'inventory'].includes(user_role)
              ?
              <StockReportDebug />
              :
              null
            }

            {
              user_role == 'customer'
              ?
              <div>
                QB Account: &nbsp; {window.currentUserCustomAccess != null ? window.currentUserCustomAccess.quickbooksAccountName : '(missing)'}
              </div>
              :
              window.currentUserCustomAccess != null
              ?
              <div>
                <div style={{border: '1px solid rgb(211,211,211)', background: 'rgba(233,233,233,0.5)', display: 'inline-block', padding: '20px', marginBottom: '70px'}}>
                Your custom access:
                <pre><code>{JSON.stringify(window.currentUserCustomAccess, null, 4)}</code></pre>
                </div>
              </div>
              :
              null
            }

            {
              user_role == 'admin' || user_role == 'inventory'
              ?
              <div style={{display: 'inline-block', textAlign: 'center'}}>
                <Message warning>
                  <Message.Header>You must create these items in QB before importing <u>todays</u> report!</Message.Header>
                  {
                    loadingMissingItems
                    ?
                    <p>Loading...</p>
                    :
                    missingItems.length == 0
                    ?
                    <p>ALL ARE CREATED</p>
                    :
                    <p>
                      {
                        missingItems.map(item => <React.Fragment> <div style={missingItemStyle}>{item}</div> </React.Fragment>)
                      }
                    </p>
                  }
                </Message>

                <br/>

                <Message>
                  <Message.Header>Download <u>SALES ORDER</u> QB Import Excel for &nbsp; <small style={{opacity: 0.5, fontSize: '12px', float: 'right'}}>(Upload to <Image src='https://erp-rails-usa-production.s3.amazonaws.com/images/qb_logo.png' avatar /> everyday at 6pm)</small></Message.Header>
                  <Message.List>
                    <Message.Item><a onClick={this.downloadQbImportExcel} style={{cursor: 'pointer'}}>Today &nbsp; ({today.toDateString()})</a></Message.Item>

                    <Message.Item><a onClick={this.downloadYesterdayQbImportExcel} style={{cursor: 'pointer'}}>Yesterday &nbsp; ({yesterday.toDateString()})</a></Message.Item>
                    <Message.Item><a onClick={this.downloadYesterday2QbImportExcel} style={{cursor: 'pointer'}}>Day before Yesterday &nbsp; ({yesterday2.toDateString()})</a></Message.Item>
                    <Message.Item><a onClick={this.downloadAllQbImportExcel} style={{cursor: 'pointer'}}>ALL (past 180 days)</a></Message.Item>

                    <center style={{fontSize: '12px'}}>This is <b><i>Pending</i></b> &nbsp;POs.  &nbsp;Reports includes previous day's orders after 6pm</center>
                  </Message.List>
                </Message>

                <br/>

                <Message>
                  <Message.Header>Download <u>SALES ORDER</u> QB Import Excel for SINGLE REF #</Message.Header>
                  <p>
                    <input id="refnumber" placeholder='Enter REF #' style={{position: 'relative', top: '2px'}}/> &nbsp;
                    <Button onClick={this.downloadQbImportExcelRefnumber}>Click to Download</Button>
                  </p>
                </Message>

                <br/>

                <DownloadVpos/>

                <br/><br/><br/><br/><br/>
              </div>
              :
              null
            }
          </div>
        }
      </div>
    )
  }
}

export default HomePage
