import * as types from '../actions/actionTypes'
import {getDifferenceInDays} from '../components/cads/date.js'
import {quickFixAllBoysAllGirls} from './quickFixAllBoysAllGirls'
import {quickFixAllInHouseBrands} from './quickFixAllInHouseBrands'

let stateVersion = 48
let defaultDateFrom = '1/1/2023'
let defaultDateTo = '12/31/2025'
window.defaultDateTo = defaultDateTo
window.defaultMaxHoldAge = getDifferenceInDays(defaultDateFrom)

const initialStateClear = {
  division: [],
  color: [],
  subCategory: [],
  sub2: [],
  fabrication: [],
  brand: [],
  category: [],
  packs: [],
  margins: [],
  season: [],
  dateFrom: defaultDateFrom,
  dateTo: defaultDateTo,
  futureAtsMonths: [],
  currentPlaylistId: null,
  currentPlaylistName: '',

  showOnHand: false,
  showATS: true,
  showBrand: false,
  showDivision: false,
  showCategory: false,
  showSubCategory: false,
  showColor: false,
  showDescription: false,
  showFabrication: false,
  showFutureStock: false,
  showFutureATS: true,
  showHold: false,
  showMSRP: false,
  showPacks: false,
  showPage: false,
  showPO: false,
  showSeason: false,
  showShipped: false,
  showSizeBreakdown: false,
  showWholesale: false,
  showWorksheet: false,
  showCountryofOrigin: false,
  showWarehouse: false,
  showContainerNumber: false,
  showFactory: false,
  showCasePack: false,
  showExFactory: false,
  showUPC: false,
  showLDPCOST: false,

  showOnHandIndex: '',
  showATSIndex: 1,
  showBrandIndex: '',
  showCategoryIndex: '',
  showColorIndex: '',
  showDescriptionIndex: '',
  showFabricationIndex: '',
  showFutureATSIndex: 2,
  showHoldIndex: '',
  showMSRPIndex: '',
  showPacksIndex: '',
  showPageIndex: '',
  showPOIndex: '',
  showSeasonIndex: '',
  showShippedIndex: '',
  showSizeBreakdownIndex: '',
  showWholesaleIndex: '',
  showWorksheetIndex: '',

  cadStatus: 'Immediate',
  defaultAccount: null,
  last5Searches: [],
  shoppingBag: {},
  factory: [],
  warehouse: [],

  print: [],
  pattern: []
};

let initialState = initialStateClear;

if (sessionStorage.getItem('initialStatev'+stateVersion) != null) {
  initialState = JSON.parse(sessionStorage.getItem('initialStatev'+stateVersion));
}

String.prototype.reverse = function () {
  return this.split('').reverse().join('');
}

const clearCadCard = () => {
  window.cadDescription = {}
}

export default (state = initialState, action) => {
 let newState;
 let shoppingBag = state.shoppingBag;
 switch (action.type) {
   case types.FILTER_APPLICABLE_SUB_CATEGORIES:

        var subCategory = state.subCategory;

        var newSubCategory = [];


        for (var i=0; i<subCategory.length; i++) {
          if (action.applicableSubCategories.indexOf(subCategory[i]) > -1) {
            newSubCategory.push(subCategory[i]);
          }
        }


        newState = Object.assign({}, state, {
          subCategory: newSubCategory
        })
        sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
        return newState;
   case types.UPDATE_LAST_5_SEARCHES:
       var newLast5Searches = state.last5Searches;
       newLast5Searches.unshift(action.newSearch);

       //get unique
       var newLast5SearchesUnique = [];
       for (var i=0; i<newLast5Searches.length; i++) {
         if (newLast5SearchesUnique.indexOf(newLast5Searches[i]) == -1 && newLast5Searches[i] != '') {
           newLast5SearchesUnique.push(newLast5Searches[i]);
         }
       }

       newState = Object.assign({}, state, {
         last5Searches: newLast5SearchesUnique.slice(0, 5)
       })
       sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
      return newState;
   case types.UPDATE_DEFAULT_ACCOUNT:

     clearCadCard()

     newState = Object.assign({}, state, {
       defaultAccount: action.defaultAccount
     })

     if (action.defaultAccount == 'All') {
       newState.defaultAccount = null
     } else {
       //default account ON!

       //show fields
       newState.showATS = true
       newState.showFutureATS = true
       newState.showPO = true
       newState.showShipped = true
       newState.showHold = true
       // newState.showWorksheet = true
     }

     sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState))
     return newState

   case types.UPDATE_CAD_STATUS:

     clearCadCard()

     let role = sessionStorage.getItem('currentUserRole')
     let isDesign = role == "design"

     newState = Object.assign({}, state, {
       cadStatus: action.cadStatus
     })

     if (action.cadStatus == "Future Stock") {
       newState.showFutureStock = true
     }

     if (["Immediate", "ATS", "ATS or Future ATS"].indexOf(action.cadStatus) > -1) {
       newState.showATS = true;
     }

     if (["Future ATS", "ATS or Future ATS"].indexOf(action.cadStatus) > -1) {
       newState.showATS = true;
       newState.showFutureATS = true;
       newState.showShipped = false;
       if (!isDesign) {
         newState.showPO = true;
         newState.showHold = true;
         newState.showWorksheet = true;
       }
     }

     if (action.cadStatus == "Shipped") {
       newState.showATS = true;
       newState.showFutureATS = false;
       if (!isDesign) {
         newState.showShipped = true;
       }
       newState.showPO = false;
       newState.showHold = false;
       newState.showWorksheet = false;
     }

     if (["PO", "Hold", "Worksheet"].indexOf(action.cadStatus) > -1) {
       newState.showATS = true;
       newState.showFutureATS = true;
       if (!isDesign) {
         newState.showShipped = false;
         newState.showPO = action.cadStatus == "PO";
         newState.showHold = action.cadStatus == "Hold";
         newState.showWorksheet = action.cadStatus == "Worksheet";
       }
     }

     if (action.cadStatus == "Sizes") {
       newState.showSizeBreakdown = true;
     }

     if (action.cadStatus == "On Hand") {
       newState.showOnHand = true
     }

     if (action.cadStatus == "Pending PI") {
       newState.showFutureStock = true
       newState.showFutureATS = false
     }

     if (action.cadStatus == "Case Pack") {
       newState.showCasePack = true
     }

     if (action.cadStatus == "Container #" || action.cadStatus == "Container # w/ Updated Dates") {
       newState.showContainerNumber = true
     }

     if (action.cadStatus == "Factory") {
       newState.showFactory = true
     }

     if (action.cadStatus == "No Image w/ Future Stock") {
       newState.showBrand = true
       newState.showDivision = true
       newState.showCategory = true
       newState.showSubCategory = true
       newState.showFutureStock = true
       newState.showATS = false
       newState.showOnHand = false
       newState.showFutureATS = false
       newState.showPO = false
       newState.showShipped = false
       newState.showHold = false
       newState.showWorksheet = false
     }

     sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
     return newState;
  case types.UPDATE_CATEGORY:
    newState = Object.assign({}, state, {
      category: action.category
    })
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;
  case types.UPDATE_PLAYLIST:
    newState = Object.assign({}, state, {
      currentPlaylistId: action.currentPlaylistId,
      currentPlaylistName: action.currentPlaylistName
    })
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;
  case types.ON_CHANGE_HIDEABLE_INPUT:

    let fieldName2 = action.fieldName;
    let update3 = {};
    update3[fieldName2] = Number(action.value);

    newState = Object.assign({}, state, update3);
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;

  case types.TOGGLE_HIDEABLE_CHECKBOX:

    clearCadCard()

    let fieldName = action.fieldName.replace('#','Number')
    let update = {};
    update[fieldName] = !state[fieldName];

    // if (!update[fieldName]) {
    //   update[fieldName+'Index'] = ''
    //
    //   //lower the rest of the active numbers so in correct order...
    //
    //
    //
    //   let currentValuesObject = [
    //     {name: 'showOnHandIndex', value: state.showOnHandIndex},
    //     {name: 'showATSIndex', value: state.showATSIndex},
    //     {name: 'showBrandIndex', value: state.showBrandIndex},
    //     {name: 'showCategoryIndex', value: state.showCategoryIndex},
    //     {name: 'showColorIndex', value: state.showColorIndex},
    //     {name: 'showFabricationIndex', value: state.showFabricationIndex},
    //     {name: 'showFutureATSIndex', value: state.showFutureATSIndex},
    //     {name: 'showMSRPIndex', value: state.showMSRPIndex},
    //     {name: 'showPageIndex', value: state.showPageIndex},
    //     {name: 'showPOIndex', value: state.showPOIndex},
    //     {name: 'showHoldIndex', value: state.showHoldIndex},
    //     {name: 'showWorksheetIndex', value: state.showWorksheetIndex},
    //     {name: 'showSeasonIndex', value: state.showSeasonIndex},
    //     {name: 'showShippedIndex', value: state.showShippedIndex},
    //     {name: 'showSizeBreakdownIndex', value: state.showSizeBreakdownIndex},
    //     {name: 'showWholesaleIndex', value: state.showWholesaleIndex},
    //     {name: 'showPacksIndex', value: state.showPacksIndex},
    //     {name: 'showDescriptionIndex', value: state.showDescriptionIndex},
    //   ]
    //
    //   let index = currentValuesObject.findIndex(x => x.name === fieldName+'Index');
    //
    //   currentValuesObject[index].value = '';
    //
    //   function compare(a, b) {
    //     if (a.value > b.value) return 1;
    //     if (b.value > a.value) return -1;
    //
    //     return 0;
    //   }
    //
    //   currentValuesObject.sort(compare);
    //
    //   currentValuesObject = currentValuesObject.filter(x => x.value != '')
    //
    //   for (let i=0;i<currentValuesObject.length; i++) {
    //     update[currentValuesObject[i].name] = i+1;
    //   }
    //
    // } else {
    //   //get highest value and assign +1 that
    //
    //   let currentValues = [
    //     state.showOnHandIndex,
    //     state.showATSIndex,
    //     state.showBrandIndex,
    //     state.showCategoryIndex,
    //     state.showColorIndex,
    //     state.showFabricationIndex,
    //     state.showFutureATSIndex,
    //     state.showMSRPIndex,
    //     state.showPageIndex,
    //     state.showPOIndex,
    //     state.showHoldIndex,
    //     state.showWorksheetIndex,
    //     state.showSeasonIndex,
    //     state.showShippedIndex,
    //     state.showSizeBreakdownIndex,
    //     state.showWholesaleIndex,
    //     state.showPacksIndex,
    //     state.showDescriptionIndex,
    //   ]
    //
    //   let maxValue = currentValues.reduce(function(a, b) {
    //       return Math.max(a, b);
    //   });
    //
    //   update[fieldName+'Index'] = maxValue + 1;
    // }

    newState = Object.assign({}, state, update);
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;

  case types.SELECT_ALL_HIDEABLE_CHECKBOX:

    clearCadCard()

    //console.log(' case types.SELECT_ALL_HIDEABLE_CHECKBOX:  ') ;

    let fieldNames = action.fieldNames;
    let update2 = {};

    //make them all selected unless they are already all selected then toggle off/on

    let allSelected = true;

    for (var i=0; i<fieldNames.length; i++) {
      let fieldNameLocal = ('show'+fieldNames[i]).replace(/ /g, '').replace('-', '').replace('#', 'Number')

      if (state[fieldNameLocal]) {
        //if true is showing.....
        //do nothing
      } else {
        allSelected = false;
        break;
        //any one is not showing. show all
      }
    }

    for (var i=0; i<fieldNames.length; i++) {
      let fieldNameLocal = ('show'+fieldNames[i]).replace(/ /g, '').replace('-', '').replace('#', 'Number')

      update2[fieldNameLocal] = !allSelected;

      if (!allSelected) {
        //assign default numbers
        let defaultValues = {
          showATS: 1,
          showBrand: 8,
          showCategory: 9,
          showColor: 5,
          showFabrication: 4,
          showFutureATS: 2,
          showMSRP: 7,
          showPage: 17,
          showPO: 13,
          showHold: 14,
          showWorksheet: 15,
          showSeason: 12,
          showShipped: 16,
          showSizeBreakdown: 11,
          showWholesale: 6,
          showPacks: 10,
          showDescription: 3,
        }

        update2[fieldNameLocal+'Index'] = defaultValues[fieldNameLocal];
      } else {
        update2[fieldNameLocal+'Index'] = '';
      }
    }




    newState = Object.assign({}, state, update2);
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;
  case types.UPDATE_PACKS:
    newState = Object.assign({}, state, {
      packs: action.packs
    })
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;
  case types.UPDATE_MARGINS:
    newState = Object.assign({}, state, {
      margins: action.margins
    })
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;
  case types.UPDATE_FUTURE_ATS_MONTHS:
    newState = Object.assign({}, state, {
      futureAtsMonths: action.futureAtsMonths
    })
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;
  case types.UPDATE_FACTORY:
    newState = Object.assign({}, state, {
      factory: action.factory
    })
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;
  case types.UPDATE_WAREHOUSE:
    newState = Object.assign({}, state, {
      warehouse: action.warehouse
    })
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;
  case types.UPDATE_BRAND:
    let {brand} = action
    brand = quickFixAllInHouseBrands(brand)

    newState = Object.assign({}, state, {
      brand: brand
    });
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;
  case types.UPDATE_FABRICATION:
    newState = Object.assign({}, state, {
      fabrication: action.fabrication
    });
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;
  case types.UPDATE_DIVISION:
    let {division} = action
    division = quickFixAllBoysAllGirls(division)

    newState = Object.assign({}, state, {
      division: division
    });
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;
  case types.UPDATE_COLOR:
    newState = Object.assign({}, state, {
      color: action.color
    });
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;
  case types.UPDATE_SEASON:
    newState = Object.assign({}, state, {
      season: action.season
    });
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;
  case types.UPDATE_SUB_CATEGORY:
    newState = Object.assign({}, state, {
      subCategory: action.subCategory
    });

    if (newState.subCategory.length != 1) {
      newState.sub2 = []
    }
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;
  case types.UPDATE_SUB2:
    newState = Object.assign({}, state, {
      sub2: action.sub2
    });
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;
  case types.UPDATE_DATE_TO:

    clearCadCard()

    //take closest date
    let dateTo = action.dateTo;
    if (dateTo.indexOf('//') == -1 && dateTo[0] != '/' && dateTo.reverse()[2] != '/') {
      let dateToData = action.dateTo.split('/');
      let dateToDate = new Date(dateToData[2],dateToData[0]-1,dateToData[1]);
      dateTo = dateToDate.toLocaleDateString();
    }
    newState = Object.assign({}, state, {
      dateTo: dateTo
    });
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;
  case types.UPDATE_DATE_FROM:

    clearCadCard()

    //take closest date
    let dateFrom = action.dateFrom;

    if (dateFrom.indexOf('//') == -1 && dateFrom[0] != '/' && dateFrom.reverse()[2] != '/') {
      let dateFromData = action.dateFrom.split('/');
      let dateFromDate = new Date(dateFromData[2],dateFromData[0]-1,dateFromData[1]);
      dateFrom = dateFromDate.toLocaleDateString();
    }

    newState = Object.assign({}, state, {
      dateFrom: dateFrom
    });
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;
  case types.CLEAR_FILTERS:
    //sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(initialStateClear));
    //return initialStateClear;
    newState = Object.assign({}, state, {
      division: [],
      color: [],
      subCategory: [],
      sub2: [],
      fabrication: [],
      brand: [],
      category: [],
      packs: [],
      margins: [],
      season: [],
      dateFrom: defaultDateFrom,
      dateTo: defaultDateTo,
      futureAtsMonths: [],
      cadStatus: 'Immediate',
      defaultAccount: null,
      factory: [],
      warehouse: [],
      print: [],
      pattern: []
    });
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;
  case types.UPDATE_GUEST_FILTERS:
    let filters = action.filters;

    let stateUpdate = {
      defaultAccount: (filters.defaultAccount == '' ? null : filters.defaultAccount),
      cadStatus: (filters.cadStatus == '' ? null : filters.cadStatus)
    }
    if (filters.brand != null && filters.brand != undefined) {
      stateUpdate.brand = filters.brand;
    } else {
      stateUpdate.brand = [];
    }

    if (filters.division != null && filters.division != undefined) {
      stateUpdate.division = filters.division;
    } else {
      stateUpdate.division = [];
    }

    if (filters.category != null && filters.category != undefined) {
      stateUpdate.category = filters.category;
    } else {
      stateUpdate.category = [];
    }

    if (filters.subCategory != null && filters.subCategory != undefined) {
      stateUpdate.subCategory = filters.subCategory;
    } else {
      stateUpdate.subCategory = [];
    }
    window.initialSubCategory = stateUpdate.subCategory;

    if (filters.season != null && filters.season != undefined) {
      stateUpdate.season = filters.season;
    } else {
      stateUpdate.season = [];
    }

    if (filters.packs != null && filters.packs != undefined) {
      stateUpdate.packs = filters.packs;
    } else {
      stateUpdate.packs = [];
    }

    if (filters.futureAtsMonths != null && filters.futureAtsMonths != undefined) {
      stateUpdate.futureAtsMonths = filters.futureAtsMonths;
    } else {
      stateUpdate.futureAtsMonths = [];
    }

    if (filters.fabrication != null && filters.fabrication != undefined) {
      stateUpdate.fabrication = filters.fabrication;
    } else {
      stateUpdate.fabrication = [];
    }

    if (filters.color != null && filters.color != undefined) {
      stateUpdate.color = filters.color;
    } else {
      stateUpdate.color = [];
    }

    if (filters.sub2 != null && filters.sub2 != undefined) {
      stateUpdate.sub2 = filters.sub2;
    } else {
      stateUpdate.sub2 = [];
    }

    //hide show values...
    stateUpdate.showATS = filters.showATS == 'true';
    stateUpdate.showFutureATS = filters.showFutureATS == 'true';
    stateUpdate.showBrand = filters.showBrand == 'true';
    stateUpdate.showCategory = filters.showCategory == 'true';
    stateUpdate.showColor = filters.showColor == 'true';
    stateUpdate.showFabrication = filters.showFabrication == 'true';
    stateUpdate.showDescription = filters.showDescription == 'true';
    stateUpdate.showPacks = filters.showPacks == 'true';
    stateUpdate.showSeason = filters.showSeason == 'true';
    stateUpdate.showShipped = filters.showShipped == 'true';
    stateUpdate.showPO = filters.showPO == 'true';
    stateUpdate.showHold = filters.showHold == 'true';
    stateUpdate.showWorksheet = filters.showWorksheet == 'true';

    //sami said also wanted
    stateUpdate.showMSRP = filters.showMSRP == 'true';
    stateUpdate.showPage = filters.showPage == 'true';


    newState = Object.assign({}, state, stateUpdate);

    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));

    setTimeout(() => {
      var guestSearchReduxEvent = new Event('guest-search-redux');
      window.dispatchEvent(guestSearchReduxEvent);
    }, 500);

    return newState;
  case types.ADD_TO_SHOPPING_CART:
    shoppingBag[action.cadId] = true;

    newState = Object.assign({}, state, {
      shoppingBag: shoppingBag
    })
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;

  case types.REMOVE_FROM_SHOPPING_CART:
    delete shoppingBag[action.cadId];

    newState = Object.assign({}, state, {
      shoppingBag: shoppingBag
    })
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;

  case types.BULK_ADD_TO_SHOPPING_CART:
    let newShoppingBag = {};

    action.cadIds.forEach(function (cadId) {
      newShoppingBag[cadId] = true;
    });

    newState = Object.assign({}, state, {
      shoppingBag: newShoppingBag
    })
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;

  case types.BULK_REMOVE_FROM_SHOPPING_CART:
    newState = Object.assign({}, state, {
      shoppingBag: {}
    })
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;

  case types.UPDATE_PRINT:
    newState = Object.assign({}, state, {
      print: action.print
    });
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;

  case types.UPDATE_PATTERN:
    newState = Object.assign({}, state, {
      pattern: action.pattern
    });
    sessionStorage.setItem('initialStatev'+stateVersion, JSON.stringify(newState));
    return newState;


  default:
   return state
 }
}
